const initialState = { onPropertyMouseOverPropertyId: null };

const property = (state = initialState, action) => {
    switch (action.type) {
        case 'ON_PROPERTY_CARD_MOUSE_OVER': {
            return {
                ...state,
                onPropertyMouseOverPropertyId: action.propertyId,
            };
        }

        default:
            return state;
    }
};

export default property;
