import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { getOrSetRealtyWebId } from '~/lib/analytics';
import { loginUser, logoutUser } from '../../actions';
import Loading from '../Loading';
import VerifyTokenQuery from './Operations.graphql';

const getTokenFromLocalStorage = () => {
    let token = null;
    if (typeof window !== 'undefined') {
        token = window.localStorage?.getItem('access_token');
    }
    return token;
};

const getToken = (search = null) => {
    const token = getTokenFromLocalStorage();

    const queryToken = search?.authToken ?? null;

    return queryToken || token;
};

const AppStartUp = (props) => {
    const { onVerifyToken, onVerifyTokenError, children } = props;

    const router = useRouter();
    const { query } = router || {};

    const accessToken = getToken(query);
    const [isLoading, setIsLoading] = useState(!!accessToken);

    useQuery(VerifyTokenQuery, {
        ssr: false,
        skip: !accessToken,
        variables: { accessToken },
        onCompleted: (data) => {
            if (data?.verifyToken ?? false) {
                onVerifyToken(data.verifyToken);

                if (query?.redirectUrl) router.replace(query?.redirectUrl);

                setIsLoading(false);
            } else {
                onVerifyTokenError();
                setIsLoading(false);
            }
        },
    });

    useEffect(() => {
        getOrSetRealtyWebId();
    }, []);

    if (isLoading) return <Loading height="100vh" />;

    return children;
};

export default connect(
    () => ({}),
    (dispatch) => ({
        onVerifyToken(accessToken) {
            if (accessToken && accessToken.accessToken) {
                dispatch(loginUser(accessToken));
            }
        },
        onVerifyTokenError() {
            dispatch(logoutUser());
        },
    })
)(AppStartUp);
