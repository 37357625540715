import replace from 'lodash/replace';
import pickBy from 'lodash/pickBy';

import { sendEvent } from '~/lib/google-analytics-four';

const IS_BROWSER = typeof window !== 'undefined';

export const dimensionMap = {
    dimension1: 'propertyId',
    dimension2: 'agencyId',
    dimension3: 'agentId',
    dimension4: 'brokerId',
    dimension5: 's3Key',
    dimension9: 'type',
    dimension10: 'suburbId',
};

// window.gtag('config', process.env.NEXT_PUBLIC_GA_TRACKING_ID, { custom_map: dimensionMap });

export const trackAnalytics = ({
    category = null,
    action = null,
    page = null,
    suburbId = null,
    propertyId = null,
    agencyId = null,
    agentId = null,
    brokerId = null,
    s3Key = null,
    type = null,
    trackType = 'event',
}) => {
    // if (propertyId) GoogleAnalytics.ga('set', 'dimension1', propertyId);
    // if (agencyId) GoogleAnalytics.ga('set', 'dimension2', agencyId);
    // if (agentId) GoogleAnalytics.ga('set', 'dimension3', agentId);
    // if (brokerId) GoogleAnalytics.ga('set', 'dimension4', brokerId);
    // if (s3Key) GoogleAnalytics.ga('set', 'dimension5', s3Key);
    // if (type) GoogleAnalytics.ga('set', 'dimension9', type);
    // if (suburbId) GoogleAnalytics.ga('set', 'dimension10', suburbId);

    if (!IS_BROWSER) return;

    const customDimensions = {
        ...pickBy(
            {
                suburbId,
                propertyId,
                agencyId,
                agentId,
                brokerId,
                s3Key,
                type,
            },
            (value) => value !== null
        ),
    };

    if (trackType === 'event') {
        sendEvent(action, { event_category: category, ...customDimensions });

        // window.gtag('config', process.env.NEXT_PUBLIC_GA_TRACKING_ID, { custom_map: dimensionMap });

        // window.gtag('event', action, {
        //     event_category: category,
        //     ...customDimensions,
        // });
        // GoogleAnalytics.ga('send', 'event', category, action);
    } else {
        sendEvent('pageView', { page_path: page, ...customDimensions });

        // GoogleAnalytics.set({ page });
        // window.gtag('config', NEXT_PUBLIC_GA_TRACKING_ID, { page_path: url });
        // GoogleAnalytics.ga('send', 'pageview', page);

        // window.gtag('config', process.env.NEXT_PUBLIC_GA_TRACKING_ID, {
        //     page_path: page,
        //     custom_map: dimensionMap,
        //     ...customDimensions,
        // });
    }
};

export const trackPage = (pathName) => {
    const pageContent = pathName.split('/')[1];

    if (pageContent.includes(['agency', 'mortgagebroker'])) {
        const slugUrl = replace(pathName, `/${pageContent}/`, '');
        const pageContentId = slugUrl.split('-')[slugUrl.split('-').length - 1];

        if (pageContent === 'agency') {
            trackAnalytics({
                page: pathName,
                agencyId: pageContentId,
                trackType: 'pageview',
            });
        } else if (pageContent === 'mortgagebroker') {
            trackAnalytics({
                page: pathName,
                brokerId: pageContentId,
                trackType: 'pageview',
            });
        }
    } else {
        trackAnalytics({
            page: pathName,
            trackType: 'pageview',
        });
    }
};

export const captureOutboundLink = (category, url) => {
    if (!IS_BROWSER) return;
    sendEvent('click', { event_category: category, event_label: url, transport_type: 'beacon' });

    // window.gtag('event', 'click', {
    //     event_category: category,
    //     event_label: url,
    //     transport_type: 'beacon',
    // });
};
