const initialState = { isNewlyLoggedIn: null };

const topic = (state = initialState, action) => {
    switch (action.type) {
        case 'ON_LOGIN_TOPIC_CREATE': {
            return {
                ...state,
                isNewlyLoggedIn: action.isNewlyLoggedIn,
            };
        }
        default:
            return state;
    }
};

export default topic;
