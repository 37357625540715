/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { useMemo } from 'react';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import appReducers from '../../reducers';

export const REDUX_STATE_PROP_NAME = '__REDUX_STATE__';

let reduxStore;

const initialState = {};

function initStore(preloadedState = initialState) {
    return createStore(combineReducers({ ...appReducers }), preloadedState, composeWithDevTools(applyMiddleware()));
}

export const initializeStore = (preloadedState) => {
    let _store = reduxStore ?? initStore(preloadedState);

    // After navigating to a page with an initial Redux state, merge that state
    // with the current state in the store, and create a new store
    if (preloadedState && reduxStore) {
        _store = initStore({
            ...reduxStore.getState(),
            ...preloadedState,
        });
        // Reset the current store
        reduxStore = undefined;
    }

    // For SSG and SSR always create a new store
    if (typeof window === 'undefined') return _store;
    // Create the store once in the client
    if (!reduxStore) reduxStore = _store;
    return _store;
};

export function addReduxState(store, pageProps) {
    if (pageProps?.props) {
        pageProps.props[REDUX_STATE_PROP_NAME] = store.cache.extract();
    }

    return pageProps;
}

export function useStore(pageProps) {
    const state = pageProps[REDUX_STATE_PROP_NAME];
    return useMemo(() => initializeStore(state), [state]);
}
