import auth from './auth';
import app from './app';
import property from './property';
import chat from './chat';
import topic from './topic';

export default {
    auth,
    app,
    property,
    chat,
    topic,
};
