import React from 'react';
import Logo from './Logo';

type Props = {
    // eslint-disable-next-line react/require-default-props
    height?: string | number;
    // eslint-disable-next-line react/require-default-props
    width?: string | number;
    // eslint-disable-next-line react/require-default-props
    className?: string;
    // eslint-disable-next-line react/require-default-props
    isWithShadow?: boolean;
};

const Loading = ({ height = 100, width = 100, className = '', isWithShadow = true }: Props) => (
    <div className={`loading-container flex items-center justify-center p-8 text-center ${className}`}>
        <div className="mr-2 block h-auto w-auto rounded-full p-2">
            <Logo height={height} width={width} className="m-auto" isWithShadow={isWithShadow} />
        </div>
    </div>
);

export default Loading;
