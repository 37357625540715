export const capitalize = text => {
    return text
        .toLowerCase()
        .split(' ')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
};

export function equalsIgnoreCase(stringA, stringB) {
    return stringA && stringB && stringA.toUpperCase() === stringB.toUpperCase();
}

export function includesIgnoreCase(arrayString = [], searchString) {
    const data = arrayString.find(dataString => equalsIgnoreCase(dataString, searchString));

    return !!data;
}
