/*
 * action types
 */

export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const ON_PROPERTY_CARD_MOUSE_OVER = 'ON_PROPERTY_CARD_MOUSE_OVER';
export const ON_CHAT_SEARCH = 'ON_CHAT_SEARCH';
export const ON_CLOSE_BROKER_NOTIFICATION = 'ON_CLOSE_BROKER_NOTIFICATION';
export const ON_LOGIN_TOPIC_CREATE = 'ON_LOGIN_TOPIC_CREATE';
export const LOGIN_AS_USER = 'LOGIN_AS_USER';
export const LOGOUT_AS_USER = 'LOGOUT_AS_USER';

/*
 * action creators
 */

export function loginUser(accessToken) {
    return {
        type: LOGIN_USER,
        accessToken,
    };
}

export function loginTopicCreate(isNewlyLoggedIn) {
    return {
        type: ON_LOGIN_TOPIC_CREATE,
        isNewlyLoggedIn,
    };
}

export function logoutUser() {
    return { type: LOGOUT_USER };
}

export function onPropertyCardMouseOver(propertyId) {
    return {
        type: ON_PROPERTY_CARD_MOUSE_OVER,
        propertyId,
    };
}

export function onChatSearch(keyword) {
    return {
        type: ON_CHAT_SEARCH,
        keyword,
    };
}

export function onCloseBrokerNotification(isClosed) {
    return {
        type: ON_CLOSE_BROKER_NOTIFICATION,
        isClosed,
    };
}

export function loginAsUser(accessToken) {
    return {
        type: LOGIN_AS_USER,
        accessToken,
    };
}

export function logoutAsUser() {
    return { type: LOGOUT_AS_USER };
}
// export const incrementDebounced = debounceAction(onPropertyCardMouseOver, 1000);
