import { createMedia } from '@artsy/fresnel';

const AppMedia = createMedia({
    breakpoints: {
        xs: 319,
        sm: 641,
        md: 769,
        lg: 1025,
        xl: 1281,
        xxl: 1441,
        xxxl: 1601,
    },
    interactions: {
        hover: '(hover: hover)',
        notHover: '(hover: none)',
        landscape: 'not all and (orientation: landscape)',
        portrait: 'not all and (orientation: portrait)',
    },
});

// Make styles for injection into the header of the page
export const mediaStyles = AppMedia.createMediaStyle();

export const { Media, MediaContextProvider } = AppMedia;
