// import cookie from 'cookie';

const initialState = {
    isAuthenticated: false,
    accessToken: null,
    accessTokenExp: null,
    role: null,
    user: null,
    refreshToken: null,
    type: null,
    profilePageId: null,
    isLoginAsMode: false,
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_USER': {
            const { accessToken, expiresIn, refreshToken, user, role, type, isLoginAsMode } = action.accessToken;

            if (typeof document !== 'undefined') {
                // document.cookie = cookie.serialize('ctoken', accessToken, {
                //     maxAge: 4 * 60 * 60, // 30 days
                //     path: '/',
                //     sameSite: true,
                // });

                if (localStorage) {
                    localStorage.setItem('access_token', accessToken);
                    localStorage.setItem('access_token_exp', expiresIn);
                    localStorage.setItem('refresh_token', refreshToken);
                }
            }

            const userRole = role || '';

            return {
                ...state,
                accessToken,
                refreshToken,
                user,
                profilePageId: user ? user.profilePageId : null,
                role: userRole,
                isAuthenticated: true,
                accessTokenExp: expiresIn,
                type,
                isLoginAsMode,
            };
        }
        case 'LOGOUT_USER': {
            if (state.isLoginAsMode === true) {
                return { ...state };
            }

            if (typeof document !== 'undefined') {
                // document.cookie = cookie.serialize('ctoken', '', {
                //     maxAge: -1, // Expire the cookie immediately
                //     path: '/',
                //     sameSite: true,
                // });

                if (localStorage) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('access_token_exp');
                    localStorage.removeItem('refresh_token');

                    localStorage.removeItem('prev_access_token');
                    localStorage.removeItem('prev_access_token_exp');
                    localStorage.removeItem('prev_refresh_token');
                }
            }

            return {
                ...state,
                isAuthenticated: false,
                accessToken: null,
                accessTokenExp: null,
                refreshToken: null,
                profilePageId: null,
                role: null,
                user: null,
                type: null,
                isLoginAsMode: false,
            };
        }

        default:
            return state;
    }
};

export default auth;
