/* eslint-disable camelcase */
// import cookie from 'cookie';
import { nanoid } from 'nanoid';
import { execute, toPromise, HttpLink } from '@apollo/client';
import { includesIgnoreCase } from '~/utils/string';
import TrackPageView from './TrackPageViewMutation.graphql';
import TrackEvent from './TrackEventMutation.graphql';

const uri = process.env.NEXT_PUBLIC_CLIENT_API;
const link = new HttpLink({ uri });

type TrackProperties = {
    url?: string;
    path?: string;
    search?: string;
    referrer?: string;
    title?: string;
    anonymousId?: string;
    // eslint-disable-next-line no-restricted-globals
    name?: string;
    // eslint-disable-next-line camelcase
    dimension_1?: number;
    // eslint-disable-next-line camelcase
    dimension_2?: number;
    // eslint-disable-next-line camelcase
    dimension_3?: string;
    // eslint-disable-next-line camelcase
    dimension_4?: string;
    pageType?: string;
    pageId?: string;
};

const getRealtyWebId = () => {
    if (typeof window === 'undefined') return null;
    // @ts-ignore
    return window?.localStorage?.getItem('aws_web_uid') ?? null;
};

const setRealtyWebId = () => {
    if (typeof window === 'undefined') return null;
    const id = nanoid();
    // @ts-ignore
    window?.localStorage?.setItem('aws_web_uid', id);
    return id;
};

export const getOrSetRealtyWebId = () => {
    let result = getRealtyWebId();
    if (!result) result = setRealtyWebId();
    return result;
};

// const getCookie = () => {
// if (typeof window !== 'undefined') return null;
//     const { aws_web_uid } = cookie.parse(window.document.cookie);
//     return aws_web_uid || null;
// };

// const setCookie = () => {
//     const id = nanoid();
//     cookie.serialize('aws_web_uid', id);
//     return id;
// };

const getPageInfo = (pathname: string = '') => {
    if (pathname === '/') {
        return { pageType: 'home' };
    }

    const paths = `${pathname || ''}`.split('/');

    const firstPath = `${paths[1] || ''}`.toLowerCase();
    const slug = paths[paths?.length - 1];
    let pageId = null;

    if (includesIgnoreCase(['/buy/search', '/rent/search', '/sold/search', '/off-market/search'])) {
        return { pageType: 'property-search' };
    }

    let pageType = null;
    switch (firstPath) {
        case 'property':
        case 'rent':
        case 'buy':
        case 'off-market':
        case 'sold': {
            pageType = 'property';
            const pageIdString = slug.split('-')[slug.split('-')?.length - 1];
            // pageId = pageIdString;
            pageId = Number.parseInt(pageIdString, 10);
            break;
        }
        case 'agency':
        case 'agent':
        case 'live':
        case 'mortgagebroker': {
            pageType = firstPath;
            const pageIdString = slug.split('-')[slug.split('-')?.length - 1];
            // pageId = pageIdString;
            pageId = Number.parseInt(pageIdString, 10);
            break;
        }
        case 'suburb-profile': {
            pageType = 'suburb-profile';
            break;
        }
        default: {
            break;
        }
    }

    return {
        pageType,
        pageId,
    };
};

const sendAnalytics = (query, input) => {
    toPromise(
        execute(link, {
            query,
            variables: { input },
        })
    )
        .then()
        .catch((error) => console.log(`received error ${error}`));
};

export const trackEvent = (event: String, properties: TrackProperties): void => {
    if (!event || !window) return;

    const anonymousId = getOrSetRealtyWebId();

    const input = {
        url: window.location.href,
        path: window.location.pathname,
        search: window.location.search,
        referrer: document.referrer,
        title: document.title,
        anonymousId,
        name: event,
        ...getPageInfo(window.location.pathname),
        ...properties,
    };

    if (!input.url.includes('localhost')) {
        sendAnalytics(TrackEvent, input);
    }

    // sendAnalytics(TrackEvent, input);
};

export const trackPage = (name?: String, properties?: TrackProperties): void => {
    if (!window) return;

    const anonymousId = getOrSetRealtyWebId();

    // const pageInfo = getPageInfo(window.location.pathname);

    const pageInfoData = getPageInfo(window.location.pathname);

    const pageInfo = { pageId: pageInfoData?.pageId?.toString(), pageType: pageInfoData?.pageType };

    const input = {
        url: window.location.href,
        path: window.location.pathname,
        search: window.location.search,
        referrer: document.referrer,
        title: document.title,
        anonymousId,
        name: name || pageInfo.pageType || '',
        ...pageInfo,
        ...properties,
    };

    if (!input.url.includes('localhost')) {
        sendAnalytics(TrackPageView, input);
    }

    // sendAnalytics(TrackPageView, input);
};

export const linkedInTrackEvent = (options: { conversion_id: number }) => {
    if (!window) return;

    window.lintrk('track', options);
};
