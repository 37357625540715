import Script from 'next/script';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const pageview = (url: string) => {
    window.dataLayer.push({
        event: 'pageview',
        page: url,
    });
};

export const sendEvent = (eventName: string, variables: Record<string, string> = {}) => {
    window.dataLayer.push({
        event: eventName,
        ...variables,
    });
};

const GoogleAnalyticsFour = () => {
    const router = useRouter();
    useEffect(() => {
        router.events.on('routeChangeComplete', pageview);
        return () => {
            router.events.off('routeChangeComplete', pageview);
        };
    }, [router.events]);

    return (
        <>
            <Script
                strategy="afterInteractive"
                // src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_MEASUREMENT_ID}`}
                src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}`}
            />

            <Script id="google-analytics" strategy="afterInteractive">
                {`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');
                `}
            </Script>
        </>
    );
};

export default GoogleAnalyticsFour;
