import React from 'react';
import Head from 'next/head';
import { config as FAConfig } from '@fortawesome/fontawesome-svg-core';
import { DefaultSeo } from 'next-seo';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/nextjs';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '~/lib/apollo/apollo';
import { useStore } from '~/lib/redux/redux';

import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/blur-up/ls.blur-up';

// import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-vis/dist/style.css';
import 'rc-select/assets/index.css';
// import "react-multi-carousel/lib/styles.css";
import '~/styles/tailwind.css';
import '~/styles/react-multi-carousel.css';
import '~/styles/index.scss';
import '~/styles/main.scss';
import PageAnalytics from '~/components/PageAnalytics';
import AppStartUp from '~/components/AppStartup';

import { MediaContextProvider } from '~/Media';
import { isProd } from '~/utils/env';

// fix font awesome big icons on first load
FAConfig.autoAddCss = false;

// Disable for now
// export function reportWebVitals(metric) {
//     switch (metric.name) {
//         case 'Next.js-hydration':
//             // handle hydration results
//             console.log('Next.js-hydration');
//             console.log(metric);
//             break;
//         case 'Next.js-render':
//             // handle render results
//             console.log('Next.js-render');
//             console.log(metric);
//             break;
//         default:
//             break;
//     }
// }

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.init({
        enabled: isProd,
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    });
}

const MyApp = ({ Component, pageProps }) => {
    const reduxStore = useStore(pageProps);
    const apolloClient = useApollo(pageProps);

    return (
        <>
            <Head>
                <meta
                    key="google-site-verification_1"
                    name="google-site-verification"
                    content="OxcJoP3d8flyoHAvBDCJxPE9boHLVw0TlZgJyGgGteA"
                />
                <meta
                    key="google-site-verification_2"
                    name="google-site-verification"
                    content="cPOvf-bkbk40ywJmDy-27D2_3l1NBsHPjE56VJ9p8uY"
                />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=0.86, maximum-scale=3.0, minimum-scale=0.86"/> */}

                <link rel="preconnect" href="https://au.cdn.realty.com.au" />
                <link rel="dns-prefetch" href="https://au.cdn.realty.com.au" />
            </Head>
            <DefaultSeo
                title="Pre Portal Marketplace for Buyers Agents & Professionals | Realty.com.au"
                description="Access pre-portal, passive and private properties. AI-powered alerts to save your time. Curated inventory for investors. Buyers agents and professionals"
                canonical="https://www.realty.com.au"
                openGraph={{
                    // type: 'website',
                    // title: 'Inspirations for your Home | Realty.com.au',
                    // description:
                    //     'Create an account or log into Realty. Share your property inspirations with family and friends',
                    locale: 'en_US',
                    // url: 'https://www.realty.com.au',
                    site_name: 'Realty.com.au',
                    // images: [
                    //     {
                    //         url: 'https://au.cdn.realty.com.au/home_01.jpg',
                    //         width: 800,
                    //         height: 600,
                    //         alt: 'Inspirations for your Home | Realty.com.au',
                    //     },
                    // ],
                }}
                facebook={{ appId: process.env.NEXT_PUBLIC_FB_APP_ID }}
            />

            <PageAnalytics track={isProd} />
            <Provider store={reduxStore}>
                <ApolloProvider client={apolloClient}>
                    <AppStartUp>
                        <MediaContextProvider>
                            <Component {...pageProps} />
                        </MediaContextProvider>
                    </AppStartUp>
                </ApolloProvider>
            </Provider>
        </>
    );
};

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
// https://nextjs.org/docs/advanced-features/custom-app
// MyApp.getInitialProps = async appContext => {
//     // calls page's `getInitialProps` and fills `appProps.pageProps`
//     const appProps = await App.getInitialProps(appContext);

//     return { ...appProps };
// };

export default MyApp;

// export default compose(
//     withReduxStore,
//     withApolloProvider
//     // withAuth,
//     // withUserAgent
// )(MyApp);
