const initialState = { keyword: null };

const chat = (state = initialState, action) => {
    switch (action.type) {
        case 'ON_CHAT_SEARCH': {
            return {
                ...state,
                keyword: action.keyword,
            };
        }
        default:
            return state;
    }
};

export default chat;
