import { ApolloLink } from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';
import { isProd, isBrowser } from '~/utils/env';
// import sha256 from 'hash.js/lib/hash/sha/256';

// const defaultGenerateHash = query =>
//     sha256()
//         .update(print(query))
//         .digest('hex');

export const getAuthToken = () => (isBrowser && localStorage ? localStorage.getItem('access_token') : '');

export const logLink = new ApolloLink((operation, forward) => {
    if (!isProd) console.time(operation.operationName);
    return forward(operation).map((result) => {
        if (!isProd) console.timeEnd(operation.operationName);
        return result;
    });
});

export const persistentQueryLink = createPersistedQueryLink({
    useGETForHashedQueries: true,
    sha256,
    // generateHash: defaultGenerateHash,
});
