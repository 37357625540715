import React from 'react';
import styles from './Logo.module.scss';

type Props = {
    // eslint-disable-next-line react/require-default-props
    isWithShadow?: boolean;
};

const SvgComponent: React.FC<Props> = (props) => {
    const { isWithShadow, ...restProps } = props;

    return (
        <div className="loading-container">
            <svg
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="100%"
                height="100%"
                viewBox="0 0 64.436 96.602"
                {...restProps}
                // id="iconsvg"
            >
                <defs>
                    <radialGradient
                        xlinkHref="#a"
                        id="b"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="matrix(.22382 0 0 .04694 -12.14 -25.75)"
                        cx={54.988}
                        cy={422.652}
                        r={49.042}
                        fx={54.988}
                        fy={422.652}
                    />
                    <radialGradient
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="matrix(1 0 0 .3103 720.3 43.108)"
                        r={49.042}
                        cy={422.652}
                        cx={54.988}
                        id="a"
                    >
                        <stop offset={0} stopColor="#231f20" />
                        <stop offset={0.267} stopColor="#6c696a" stopOpacity={0.733} />
                        <stop offset={0.524} stopColor="#aba9aa" stopOpacity={0.475} />
                        <stop offset={0.74} stopColor="#d8d8d8" stopOpacity={0.26} />
                        <stop offset={0.905} stopColor="#f5f4f4" stopOpacity={0.095} />
                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </radialGradient>
                </defs>
                <g transform="matrix(3.30594 0 0 3.29784 31.628 108.51)" strokeWidth={0.303}>
                    <path
                        d="M.134-10.145s-6.749-7.088-8.047-12.016c-.337 0-1.172-.006-1.172-.006s.687-.565 1.007-.803a4.948 4.948 0 0 1-.05-.684c.005-6.904 5.096-9.083 8.268-9.083 2.13 0 5.15.835 6.9 3.562.166-.002.316 0 .416-.002l.001.755c.608 1.234.979 2.789.972 4.733 0 .24-.01.495-.049.75.108.113.775.592 1.029.775-.197 0-.807.014-1.199.014C6.87-17.215.134-10.145.134-10.145z"
                        fill="none"
                        stroke="#3b82f6"
                        // id="teardrop"
                        className={styles.teardrop}
                        strokeWidth={0.454}
                    />
                    <path
                        d="M-9.085-22.167l9.219-7.23 4.92 3.859.007-3.634 1.989-.003h.407l-.001.682-.008 4.832 1.96 1.497-2.31.025v2.557C5.352-15.822.135-10.08.135-10.08s-4.977-5.44-6.724-9.05v-3.037z"
                        fill="none"
                        stroke="#3b82f6"
                        strokeWidth={0.454}
                        // id="house"
                        className={styles.house}
                    />
                    <g
                        fill="#3b82f6"
                        fillOpacity={0.984}
                        fillRule="evenodd"
                        stroke="#3b82f6"
                        strokeWidth={0.273}
                        strokeLinecap="square"
                        // id="window"
                        className={styles.window}
                    >
                        <rect
                            width={2.367}
                            height={2.24}
                            x={-2.536}
                            y={-21.834}
                            ry={0}
                            paintOrder="markers fill stroke"
                        />
                        <rect
                            ry={0}
                            y={-21.834}
                            x={0.654}
                            height={2.24}
                            width={2.367}
                            paintOrder="markers fill stroke"
                        />
                        <rect
                            ry={0}
                            y={-18.896}
                            x={-2.536}
                            height={2.24}
                            width={2.367}
                            paintOrder="markers fill stroke"
                        />
                        <rect
                            width={2.367}
                            height={2.24}
                            x={0.654}
                            y={-18.896}
                            ry={0}
                            paintOrder="markers fill stroke"
                        />
                    </g>
                    {isWithShadow ? (
                        <ellipse
                            className={styles.shadow}
                            cx={0.168}
                            cy={-5.91}
                            rx={7.413}
                            ry={2.3}
                            opacity={0.17}
                            fill="url(#b)"
                            strokeWidth={0.08}
                        />
                    ) : null}
                </g>
            </svg>
        </div>
    );
};

export default SvgComponent;
