import React, { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { trackPage as trackPageView } from '~/lib/analytics';
import { trackPage } from '~/utils/analytics';

const trackPixelPageView = () => {
    window.fbq('track', 'PageView');
};

const handleRouteChange = (url) => {
    trackPage(url);
    trackPageView();
    trackPixelPageView();
};

const trackFirstView = () => {
    trackPageView();
    trackPixelPageView();
};

// https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics
// https://github.com/vercel/next.js/tree/canary/examples/with-facebook-pixel

type Props = {
    track: boolean;
};

const PageAnalytics: React.FC<Props> = ({ track = true }) => {
    const router = useRouter();

    const onRouteChange = useCallback(
        (url) => {
            if (!track) return;
            handleRouteChange(url);
        },
        [track]
    );

    useEffect(() => {
        // This pageview only trigger first time (it is important for Pixel to have real information)
        if (track) trackFirstView();
        router.events.on('routeChangeComplete', onRouteChange);
        return () => {
            router.events.off('routeChangeComplete', onRouteChange);
        };
    }, [router.events, track, onRouteChange]);

    return (
        <>
            <Script id="facebook-pixel">
                {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${process.env.NEXT_PUBLIC_FB_PIXEL_ID});
        fbq('track', 'PageView');
      `}
            </Script>
            <Script
                strategy="afterInteractive"
                // src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_MEASUREMENT_ID}`}
                src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}`}
            />

            <Script id="google-analytics" strategy="afterInteractive">
                {`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');
                `}
            </Script>
            <Script id="linkedin-analytics-init" strategy="afterInteractive">
                {`
                    _linkedin_partner_id = "${process.env.NEXT_PUBLIC_LINKEDIN_PARTNER_ID}";
                    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                `}
            </Script>
            <Script id="linkedin-analytics-script" strategy="afterInteractive">
                {`
                    (function(l) {
                    if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                    window.lintrk.q=[]}
                    var s = document.getElementsByTagName("script")[0];
                    var b = document.createElement("script");
                    b.type = "text/javascript";b.async = true;
                    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                    s.parentNode.insertBefore(b, s);})(window.lintrk);
                `}
            </Script>
        </>
    );
};

export default PageAnalytics;
